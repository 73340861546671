<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card grid-list-lg>
      <ModelTitle title="Edit Expense" @close="close()" />

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <DateSelector v-if="dialog" :initValue="Expense.date" v-model="Expense.date" />
          </v-col>
          <v-col cols="12" sm="12">
            <v-autocomplete
              outlined
              dense
              auto-select-first
              :loading="loading"
              :items="Payee"
              label="Select Payee"
              item-text="name"
              :hide-details="selectPayeeError.length === 0"
              :error-messages="selectPayeeError"
              item-value="id"
              v-model="Expense.PayeeId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="6" offset-md="6">
            <v-text-field
              outlined
              dense
              hide-details
              @focus="$event.target.select()"
              class="right-input"
              v-model="Expense.amount"
              label="Amount"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <v-textarea
              @focus="$event.target.select()"
              outlined
              dense
              auto-grow
              hide-details
              v-model="Expense.note"
              :label="$t('labels.description')"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="submit()"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import expenseService from "../service.js";
import payeeService from "../Payee/service.js";
import DateSelector from "../../../components/DateSelector";

const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");

export default {
  props: {
    editDialog: {
      default: false,
    },
    editExpense: {
      type: Object,
    },
  },
  components: {
    DateSelector,
  },
  name: "update-expense",
  data() {
    return {
      loading: false,
      dialog: false,
      Payee: [],
      Expense: {
        date: this.$moment().toISOString(),
        PayeeId: null,
        amount: 0,
        note: null,
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    Expense: {
      PayeeId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    selectPayeeError() {
      const errors = [];
      if (!this.$v.Expense.PayeeId.$dirty) return errors;
      if (!this.$v.Expense.PayeeId.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Expense.PayeeId.minValue)
        errors.push("Pleaes select source");

      return errors;
    },
  },
  watch: {
    editDialog(val) {
      
      this.Expense = JSON.parse(JSON.stringify(this.editExpense))
      this.dialog = val;
      this.getPayee();
    },
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Expense = {
        createdAt: this.$moment().toISOString(),
        PayeeId: null,
        amount: 0,
        note: null,
      };
      this.$v.$reset();
    },
    getPayee() {
      this.loading = true;
      return payeeService.getAll().then((response) => {
        this.loading = false;
        this.Payee = response.data;
        return response;
      });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.loading = true;

        return expenseService
          .update(this.Expense.id, this.Expense)
          .then((result) => {
            // console.log("result", result);

            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Expense is updated",
                });
              this.loading = false;
              this.$events.emit("expenseListEvent");

              this.$emit("submit");
              this.close();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style scoped></style>
